var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pt-7"},[_c('div',{staticClass:"table-page d-flex flex-column justify-space-between"},[_c('div',[(_vm.showFilters)?[_c('v-row',{staticClass:"pa-3",attrs:{"justify":"end"}},[(_vm.userIsAdmin)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.crcs,"item-text":"description","item-value":"id","outlined":"","color":"secondary-color-1","label":"Todos CRCs"},on:{"change":function($event){return _vm.setFilter('crcId', $event)}}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.types,"item-text":"description","item-value":"id","outlined":"","color":"secondary-color-1","label":"Todos os tipos"},on:{"change":function($event){return _vm.setFilter('type', $event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('v-select',{attrs:{"item-text":"description","item-value":"id","outlined":"","items":_vm.citys,"color":"secondary-color-1","label":"Todas as cidades","clearable":""},on:{"change":function($event){return _vm.setFilter('city', $event)},"click:clear":function ($event) {
                    _vm.dates = [];
                    _vm.setFilter('city', null);
                  }}})],1),_c('v-col',{attrs:{"cols":"12","md":"5","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","flat":"","full-width":"","value":_vm.computedDateFormatted,"readonly":"","type":"calendar","color":"secondary-color-1","background-color":"white-1","label":"Calendario","clearable":""},on:{"click:clear":function ($event) {
                        _vm.dates = [];
                        _vm.handleDateRangeFilter([]);
                      }}},'v-text-field',attrs,false),on))]}}],null,false,3165041973),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"header-color":"primary-color-2","color":"primary-color-2","locale":"pt-br","range":""},on:{"change":function ($event) {
                      _vm.menu1 = false;
                      _vm.handleDateRangeFilter($event);
                    }},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('v-col',{attrs:{"cols":"8","md":"5","lg":"3"}},[_c('v-text-field',{attrs:{"solo":"","flat":"","full-width":"","color":"secondary-color-1","background-color":"white-1","placeholder":"Buscar doador","prepend-inner-icon":"mdi-magnify"},on:{"input":function($event){return _vm.setFilterDebounced('name', $event)}}})],1),_c('v-col',{attrs:{"cols":"4","md":"2","lg":"1"}},[_c('report-options',{attrs:{"fileName":'Histórico',"header":_vm.headerReport,"body":_vm.collectionHistoric}})],1)],1),_c('v-divider')]:_vm._e(),(!_vm.collectionHistoric.length)?[_c('div',{staticClass:"mt-16"},[(!_vm.filterPristine)?_c('data-not-found',{attrs:{"title":"Histórico não encontrado.","imgSrc":require('@/assets/collection-historic.png')}}):_vm._e(),(_vm.filterPristine)?_c('data-not-found',{attrs:{"title":"Você ainda não não possui nenhum histórico?","btnLabel":"Cadastrar a primeira solicitação","btnAction":_vm.goToAddRequest,"imgSrc":require('@/assets/collection-historic.png')}}):_vm._e()],1)]:_vm._e(),(_vm.collectionHistoric.length)?[_c('div',[_c('v-data-table',{attrs:{"items":_vm.collectionHistoric,"headers":_vm.headers,"server-items-length":_vm.tableInfo.totalItems,"options":_vm.dataTableOptions,"hide-default-footer":""},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"item.requesterId",fn:function(ref){
                    var item = ref.item;
return [_c('request-menu-item',{attrs:{"data":item,"isHistoric":true,"canCancelRequest":_vm.canCancelRequest,"text":"Cancelar solicitação"},on:{"updateData":_vm.updateData}})]}}],null,true)})],1)]:_vm._e()],2),(this.collectionHistoric.length)?_c('div',[_c('pagination',{attrs:{"currentPage":_vm.tableInfo.currentPage,"totalPages":_vm.tableInfo.totalPages,"itemsPerPage":_vm.tableInfo.itemsPerPage},on:{"currentPageChanged":function($event){return _vm.handleCurrentPageChange($event)},"itemsPerPageChanged":function($event){return _vm.handleItemsPerPageChange($event)}}})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }