<template>
  <div>
    <v-card class="pt-7">
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <template v-if="showFilters">
            <v-row justify="end" class="pa-3">
              <v-col cols="12" md="3" v-if="userIsAdmin">
                <v-select
                  :items="crcs"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('crcId', $event)"
                  color="secondary-color-1"
                  label="Todos CRCs"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" lg="2">
                <v-select
                  :items="types"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('type', $event)"
                  color="secondary-color-1"
                  label="Todos os tipos"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" lg="2">
                <v-select
                  item-text="description"
                  item-value="id"
                  outlined
                  :items="citys"
                  @change="setFilter('city', $event)"
                  color="secondary-color-1"
                  label="Todas as cidades"
                  @click:clear="
                    $event => {
                      dates = [];
                      setFilter('city', null);
                    }
                  "
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" md="5" lg="3">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      solo
                      flat
                      full-width
                      :value="computedDateFormatted"
                      readonly
                      type="calendar"
                      color="secondary-color-1"
                      background-color="white-1"
                      label="Calendario"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="
                        $event => {
                          dates = [];
                          handleDateRangeFilter([]);
                        }
                      "
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    header-color="primary-color-2"
                    color="primary-color-2"
                    locale="pt-br"
                    range
                    @change="
                      $event => {
                        menu1 = false;
                        handleDateRangeFilter($event);
                      }
                    "
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="8" md="5" lg="3">
                <v-text-field
                  @input="setFilterDebounced('name', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar doador"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

              <v-col cols="4" md="2" lg="1">
                <report-options
                  :fileName="'Histórico'"
                  :header="headerReport"
                  :body="collectionHistoric"
                />
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          <template v-if="!collectionHistoric.length">
            <div class="mt-16">
              <data-not-found
                v-if="!filterPristine"
                title="Histórico não encontrado."
                :imgSrc="require('@/assets/collection-historic.png')"
              />
              <data-not-found
                v-if="filterPristine"
                title="Você ainda não não possui nenhum histórico?"
                btnLabel="Cadastrar a primeira solicitação"
                :btnAction="goToAddRequest"
                :imgSrc="require('@/assets/collection-historic.png')"
              />
            </div>
          </template>
          <template v-if="collectionHistoric.length">
            <div>
              <v-data-table
                :items="collectionHistoric"
                :headers="headers"
                :server-items-length="tableInfo.totalItems"
                :options.sync="dataTableOptions"
                hide-default-footer
              >
                <template v-slot:[`item.requesterId`]="{ item }">
                  <request-menu-item
                    :data="item"
                    :isHistoric="true"
                    @updateData="updateData"
                    :canCancelRequest="canCancelRequest"
                    text="Cancelar solicitação"
                  />
                </template>
              </v-data-table>
            </div>
          </template>
        </div>
        <div v-if="this.collectionHistoric.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
  import { debounce } from "lodash";
  import { format } from "date-fns";
  import { mapGetters, mapActions } from "vuex";
  import { ptBR } from "date-fns/locale";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";
  import RequestMenuItem from "@/modules/agenda/views/components/RequestMenuItem";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  import { profiles } from "@/modules/core/enums/profiles.enum";

  export default {
    name: "Historic",

    mixins: [MasksMixin, TableHandler],

    components: {
      DataNotFound,
      Pagination,
      ReportOptions,
      RequestMenuItem
    },

    data: () => ({
      search: "",
      userCanEdit: true,
      hasData: true,
      dates: [],
      menu1: null,
      collectionHistoric: [],
      collectionHistoricReport: [],
      items: ["Solicitações", "Agendamento", "Histórico"],
      headers: [
        {
          text: "Lote",
          align: "start",
          value: "batchNumber"
        },
        { text: "Data/Hora", value: "createdAt" },
        { text: "Tipo", value: "type" },
        { text: "Doador/Parceiro", value: "sourceName" },
        { text: "E-mail Doador/Parceiro", value: "sourceEmail" },
        { text: "Telefone", value: "phone", sortable: false },
        { text: "Cidade", value: "city" },
        { text: "Status", value: "situation" },
        { value: "requesterId", sortable: false }
      ],
      citys: [],
      types: [
        {
          description: "PEV",
          id: 1
        },
        {
          description: "Doacão",
          id: 2
        },
        {
          description: "Campanha",
          id: 3
        },
        {
          description: "Desfazimento",
          id: 4
        },
        {
          description: "Gincana",
          id: 5
        },
        {
          description: "Todos",
          id: null
        }
      ],
      headerReport: [
        {
          header: "ID",
          dataKey: "id"
        },
        { header: "Data/Hora", dataKey: "createdAt" },
        { header: "Tipo", dataKey: "type" },
        { header: "Doador/Parceiro", dataKey: "sourceName" },
        { header: "E-mail Doador/Parceiro", dataKey: "sourceEmail" },
        { header: "Telefone", dataKey: "phone" },
        { header: "Cidade", dataKey: "city" },
        { header: "Status", dataKey: "situation" }
      ],

      canCancelRequest: false,

      crcs: [
        {
          id: null,
          description: null
        }
      ]
    }),

    props: {
      tab: {
        type: null,
        default: () => ""
      }
    },

    watch: {
      async tab() {
        this.initFilter("name", "");
        await this.getColletionData();
        await this.getCitysMethod();
      }
    },

    created() {
      this.initFilter("name", "");
    },

    async mounted() {
      this.initPermissions();
      await this.getColletionData();
      await this.getCitysMethod();
      if (this.userIsAdmin) {
        this.fetchCrcs();
      }
    },

    computed: {
      ...mapGetters({
        user: "user/getUser",
        userIsAdmin: "user/getUserIsAdmin",
        userIsManagerOperator: "user/getUserIsManagerOperator"
      }),

      computedDateFormatted() {
        if (!this.dates) {
          return "";
        }

        const formatedInitDate = this.dates[0]
          ? this.dates[0]
              .split("-")
              .reverse()
              .join("/")
          : "";

        const formatedEndDate = this.dates[1]
          ? this.dates[1]
              .split("-")
              .reverse()
              .join("/")
          : "";

        return this.dates[0]
          ? `${formatedInitDate} ~ ${formatedEndDate}`
          : "Calendario";
      },

      showFilters() {
        if (this.collectionHistoric.length) {
          return true;
        }
        return !this.collectionHistoric.length && !this.filterPristine;
      },
      canManageUser() {
        return this.userIsAdmin || this.userIsManagerOperator;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions("agenda", ["getCollectionRequestHistoric", "getCitys"]),

      initPermissions() {
        const usersCanCancelRequest = [
          profiles.MANAGER_OPERATING.id,
          profiles.RECEPTIONIST.id
        ];

        this.canCancelRequest = usersCanCancelRequest.includes(
          this.user.role.id
        );
      },

      async getColletionData() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getCollectionRequestHistoric({
            ...payload
          });

          this.collectionHistoric = this.formatCollectionRequestData(data);

          this.setTableInfo(meta);
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs();
          this.crcs = [
            ...data,
            {
              description: "Todos",
              id: ""
            }
          ];
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async getCitysMethod() {
        try {
          const { data } = await this.getCitys();

          this.citys = data;
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async setDate() {
        try {
          const payload = {
            initialDate: this.date[0],
            finalDate: this.date[1]
          };

          const { data, meta } = await this.getCollectionRequestHistoric({
            ...payload
          });

          this.collectionHistoric = this.formatCollectionRequestData(data);
          this.setTableInfo(meta);
          this.updateData();
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      goToAddRequest() {
        this.$router.push({
          name: "newRequest"
        });
      },

      updateData() {
        this.getColletionData();
      },

      formatCollectionRequestData(collection) {
        return collection.map(data => {
          return {
            ...data,
            createdAt: format(new Date(data.createdAt), "dd/MM/yyyy - HH:mm", {
              locale: ptBR
            }),
            batchNumber: data.batchNumber || "não registrado"
          };
        });
      },

      debounceInput: debounce(async function(value) {
        this.searchCRC = value;
      }, 500),

      handleDateRangeFilter(date) {
        const [initialDate, finalDate] = date;
        this.setMultipleFilters({
          initialDate,
          finalDate
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  @include search-bar();
</style>
