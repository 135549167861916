<template>
  <div>
    <v-card class="pt-7">
      <v-row justify="end">
        <template v-if="showFilters">
          <v-row justify="end" class="pa-3">
            <v-col cols="12" md="3" v-if="userIsAdmin">
              <v-select
                :items="crcs"
                item-text="description"
                item-value="id"
                outlined
                @change="setFilter('crcId', $event)"
                color="secondary-color-1"
                label="Todos CRCs"
              ></v-select>
            </v-col>
          </v-row>
        </template>
        <v-col cols="4" sm="4" md="2">
          <report-options
            :fileName="'Agendamento'"
            :header="headerReport"
            :body="collectionScheduleReport"
          />
        </v-col>
      </v-row>
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <template v-if="!collectionSchedule.length">
            <data-not-found
              class="mt-16"
              v-if="!filterPristine"
              title="Você ainda não cadastrou nenhum agendamento, deseja cadastrar?"
              btnLabel="Cadastrar o primeiro agendamento"
              :btnAction="goToAddSchedule"
              :imgSrc="require('@/assets/collection-schedule.png')"
            />
          </template>
          <template v-if="collectionSchedule.length">
            <v-data-table
              :items="collectionSchedule"
              :headers="headers"
              :items-per-page="tableInfo.itemsPerPage"
              hide-default-footer
              class="mt-15"
            >
              <template v-slot:[`item.requesterId`]="{ item }">
                <collection-menu-item
                  :user="item"
                  @updateData="updateData"
                  :canManageUser="canManageUser"
                  text="Cancelar agendamento"
                />
              </template>
            </v-data-table>
          </template>
        </div>
        <div v-if="this.collectionSchedule.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
  import { debounce, isEmpty } from "lodash";
  import { format, parseISO } from "date-fns";
  import { mapGetters, mapActions } from "vuex";

  import CollectionMenuItem from "@/modules/agenda/views/components/CollectionMenuItem";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";

  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  export default {
    name: "CollectionSchedule",

    mixins: [MasksMixin, TableHandler],

    components: {
      CollectionMenuItem,
      DataNotFound,
      Pagination,
      ReportOptions
    },

    data: () => ({
      search: "",
      userCanEdit: true,
      hasData: true,
      collectionSchedule: [],
      collectionScheduleReport: [],
      items: ["Solicitações", "Agendamento", "Histórico"],
      headers: [
        {
          text: "ID Coleta",
          align: "start",
          value: "id"
        },
        { text: "Data do Agendamento", value: "scheduleDate" },
        { text: "Turno", value: "shift" },
        { text: "Motorista", value: "driver" },
        { text: "Contato Motorista", value: "driverContact" },
        { text: "coletas", value: "amountOfCollections" },
        { text: "Status", value: "situation" },
        { value: "requesterId", sortable: false }
      ],
      headerReport: [
        {
          header: "ID",
          dataKey: "id"
        },
        { header: "Data do Agendamento", dataKey: "scheduleDate" },
        { header: "Turno", dataKey: "shift" },
        { header: "Motorista", dataKey: "driver" },
        { header: "Contato Motorista", dataKey: "driverContact" },
        { header: "coletas", dataKey: "amountOfCollections" },
        { header: "Status", dataKey: "situation" }
      ],
      crcs: [
        {
          id: null,
          description: null
        }
      ]
    }),

    props: {
      tab: {
        type: null,
        default: () => ""
      }
    },

    watch: {
      async tab() {
        await this.getColletionData();
      }
    },

    async mounted() {
      await this.getColletionData();
      if (this.userIsAdmin) {
        this.fetchCrcs();
      }
    },

    computed: {
      ...mapGetters({
        user: "user/getUser",
        userIsAdmin: "user/getUserIsAdmin",
        userIsManagerOperator: "user/getUserIsManagerOperator"
      }),
      showFilters() {
        if (this.collectionSchedule?.length) {
          return true;
        }
        return !this.collectionSchedule?.length && !this.filterPristine;
      },
      canManageUser() {
        return this.userIsAdmin || this.userIsManagerOperator;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions("agenda", ["getCollectionSchedule"]),

      async getColletionData() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getCollectionSchedule(payload);
          this.collectionSchedule = this.formatCollectionScheduleData(data);

          this.collectionScheduleReport = [...this.collectionSchedule];

          this.setTableInfo(meta);
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs();
          this.crcs = [
            ...data,
            {
              description: "Todos",
              id: ""
            }
          ];
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      updateData() {
        this.getColletionData();
      },

      goToAddSchedule() {
        this.$router.push({ name: "newSchedule" });
      },

      formatCollectionScheduleData(collection) {
        return collection.map(data => {
          const phone = !isEmpty(data.driver?.phones[0])
            ? this.parsePhone(data.driver?.phones[0])
            : "não informado";

          return {
            id: data.id,
            scheduleDate: format(parseISO(data.scheduleDate), "dd/MM/yyyy"),
            shift: data.shift,
            driver: `${data.driver?.firstName} ${data.driver?.surname}`,
            driverContact: phone,
            amountOfCollections: data.amountOfCollections,
            phone: phone ? phone : "Não informado",
            situation: data.situation?.description,
            address: data.address,
            requesterId: data.id
          };
        });
      },

      debounceInput: debounce(async function(value) {
        this.searchCRC = value;
      }, 500)
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  @include search-bar();
  .tab-active {
    background: white !important;
    color: $primary-color-2;
  }
</style>
