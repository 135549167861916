<template>
  <v-container>
    <title-header-action
      :hasActionPermission="canRegister"
      :btnLabel="titleActionLabel"
      :btnAction="handleTitleAction"
    />

    <v-tabs
      active-class="tab-active"
      hide-slider
      background-color="transparent"
      v-model="tab"
    >
      <v-tab
        class="text-capitalize primary-color-2--text"
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <collection-request :tab="tab" />
        </v-tab-item>
        <v-tab-item>
          <collection-schedule :tab="tab" />
        </v-tab-item>
        <v-tab-item>
          <historic :tab="tab" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";

  import CollectionRequest from "@/modules/agenda/views/components/CollectionRequest";
  import Historic from "@/modules/agenda/views/components/Historic";
  import CollectionSchedule from "@/modules/agenda/views/components/CollectionSchedule";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";

  export default {
    name: "Schedules",

    components: {
      CollectionRequest,
      CollectionSchedule,
      Historic,
      TitleHeaderAction
    },

    data: () => ({
      userCanEdit: true,
      tab: null,
      TABS: {
        REQUEST: 0,
        SCHEDULE: 1,
        HISTORIC: 2
      },
      items: ["Solicitações", "Agendamento", "Histórico"]
    }),

    computed: {
      ...mapGetters({
        user: "user/getUser",
        routes: "getRoutes"
      }),
      titleActionLabel() {
        return this.tab === this.TABS.REQUEST
          ? "Nova solicitação"
          : "Novo agendamento";
      },
      canRegister() {
        if (this.tab === this.TABS.REQUEST) {
          return this.routes?.some(
            route =>
              route.name === "newRequest" &&
              route?.meta?.permissions.includes(this.user.role.id)
          );
        }

        if (this.tab === this.TABS.SCHEDULE) {
          return this.routes?.some(
            route =>
              route.name === "newSchedule" &&
              route?.meta?.permissions.includes(this.user.role.id)
          );
        }

        return false;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),

      handleTitleAction() {
        if (this.tab === 0) {
          return this.$router.push({ name: "newRequest" });
        }
        return this.$router.push({ name: "newSchedule" });
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  .tab-active {
    background: white !important;
  }

  .v-tab {
    border-radius: 6px 6px 0 0;
    &[aria-selected="false"] {
      background: rgba(255, 255, 255, 0.12);
      color: white !important;
    }
  }
</style>
