import { render, staticRenderFns } from "./CollectionMenuItem.vue?vue&type=template&id=431afe04&scoped=true&"
import script from "./CollectionMenuItem.vue?vue&type=script&lang=js&"
export * from "./CollectionMenuItem.vue?vue&type=script&lang=js&"
import style0 from "./CollectionMenuItem.vue?vue&type=style&index=0&id=431afe04&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "431afe04",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VListItem,VListItemTitle,VMenu})
