<template>
  <div>
    <v-menu transition="slide-x-transition" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on"> mdi-dots-horizontal </v-icon>
      </template>
      <v-list>
        <v-list-item
          @click="viewRequest"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-account</v-icon
            ><span class="pl-3">Ver informações</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="canManageUser && situationState.canChangeSituation"
          @click="situationState.dialog = true"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-block-helper</v-icon
            ><span class="pl-2">Cancelar agendamento</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <change-schedule-situation-dialog
      :user="user"
      :state="situationState"
      @success="updateData"
    />
  </div>
</template>
<script>
  import ChangeScheduleSituationDialog from "@/modules/agenda/views/components/ChangeScheduleSituationDialog";

  import { mapActions } from "vuex";

  export default {
    name: "CollectionMenuItem",

    props: {
      user: {
        required: true,
        type: Object
      },
      canManageUser: {
        required: true,
        type: Boolean
      },
      text: {
        required: true,
        type: String
      }
    },
    components: {
      ChangeScheduleSituationDialog
    },
    data: () => ({
      situationState: {
        dialog: false,
        canChangeSituation: false,
        mode: {
          icon: null,
          label: ""
        }
      }
    }),

    methods: {
      viewRequest() {
        return this.$router.push({
          name: "ViewSchedule",
          params: { id: this.user.id }
        });
      },
      updateData() {
        this.$emit("updateData");
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .item-dropdown-user:hover {
    background-color: $white-1;

    .item-dropdown-icon {
      color: $primary-color-2;
    }
  }
</style>
