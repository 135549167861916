<template>
  <div>
    <v-card class="pt-7">
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <template v-if="showFilters">
            <v-row justify="end" class="pa-3">
              <v-col cols="12" md="3" v-if="userIsAdmin">
                <v-select
                  :items="crcs"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('crcId', $event)"
                  color="secondary-color-1"
                  label="Todos CRCs"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  @input="setFilterDebounced('name', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar doador"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="4" md="2">
                <report-options
                  fileName="Solicitações"
                  :header="headerReport"
                  :body="collectionRequestReport"
                />
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          <template v-if="!collectionRequest.length">
            <div class="mt-5">
              <data-not-found
                class="mt-16"
                v-if="!filterPristine"
                title="Solicitação não encontrada."
                :imgSrc="require('@/assets/collection-request.png')"
              />
              <data-not-found
                class="mt-16"
                v-if="filterPristine"
                title="Você ainda não cadastrou nenhuma solicitação, deseja cadastrar?"
                btnLabel="Cadastrar a primeira solicitação"
                :btnAction="goToAddRequest"
                :imgSrc="require('@/assets/collection-request.png')"
              />
            </div>
          </template>
          <template v-if="collectionRequest.length">
            <div>
              <v-data-table
                :items="collectionRequest"
                :headers="headers"
                :items-per-page="tableInfo.itemsPerPage"
                hide-default-footer
              >
                <template v-slot:[`item.situationId`]="{ item }">
                  <request-menu-item
                    :data="item"
                    @updateData="updateData"
                    :canCancelRequest="canCancelRequest"
                    text="Cancelar solicitação"
                  />
                </template>
              </v-data-table>
            </div>
          </template>
        </div>
        <div v-if="this.collectionRequest.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
  import { debounce } from "lodash";
  import { mapGetters, mapActions } from "vuex";

  import { requestSituation } from "@/modules/agenda/enums/request-situation.enum";
  import { profiles } from "@/modules/core/enums/profiles.enum";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import RequestMenuItem from "@/modules/agenda/views/components/RequestMenuItem";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  export default {
    name: "CollectionRequest",

    mixins: [MasksMixin, TableHandler],

    components: {
      DataNotFound,
      Pagination,
      ReportOptions,
      RequestMenuItem
    },

    data: () => ({
      search: "",
      userCanEdit: true,
      hasData: true,
      collectionRequest: [],
      collectionRequestReport: [],
      items: ["Solicitações", "Agendamento", "Histórico"],
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id"
        },
        { text: "Data/Hora", value: "createdAt" },
        { text: "Tipo", value: "description" },
        { text: "Doador/Parceiro", value: "sourceName" },
        { text: "Telefone", value: "phone" },
        { text: "Cidade", value: "address.city" },
        { text: "Status", value: "situation" },
        { value: "situationId", sortable: false }
      ],

      headerReport: [
        {
          header: "ID",
          dataKey: "id"
        },
        { header: "Data/Hora", dataKey: "createdAt" },
        { header: "Tipo", dataKey: "description" },
        { header: "Doador/Parceiro", dataKey: "sourceName" },
        { header: "Telefone", dataKey: "phone" },
        { header: "Cidade", dataKey: "address" },
        { header: "Status", dataKey: "situation" }
      ],

      crcs: [
        {
          id: null,
          description: null
        }
      ],

      canCancelRequest: false
    }),

    props: {
      tab: {
        type: null,
        default: () => ""
      }
    },

    watch: {
      async tab() {
        this.initFilter("name", null);
        await this.getCollectionData();
      }
    },

    created() {
      this.initFilter("name", "");
    },

    async mounted() {
      this.initPermissions();
      await this.getCollectionData();
      if (this.userIsAdmin) {
        this.fetchCrcs();
      }
      this.collectionRequestReport = [...this.collectionRequest].map(
        request => {
          return {
            ...request,
            address: request.address.city
          };
        }
      );
    },

    computed: {
      ...mapGetters({
        user: "user/getUser",
        userIsAdmin: "user/getUserIsAdmin",
        userIsManagerOperator: "user/getUserIsManagerOperator"
      }),

      showFilters() {
        if (this.collectionRequest.length) {
          return true;
        }
        return !this.collectionRequest.length && !this.filterPristine;
      },

      canManageUser() {
        return this.userIsAdmin || this.userIsManagerOperator;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions("agenda", ["getCollection"]),

      initPermissions() {
        const usersCanCancelRequest = [
          profiles.MANAGER_OPERATING.id,
          profiles.RECEPTIONIST.id
        ];

        this.canCancelRequest = usersCanCancelRequest.includes(
          this.user.role.id
        );
      },

      async getCollectionData() {
        try {
          const payload = this.getPayloadData();

          const { data, meta } = await this.getCollection({
            ...payload,
            situations: `${requestSituation.AWAIT_SCHEDULE.id},${requestSituation.RESCHEDULE_REQUESTED.id}`
          });

          this.collectionRequest = this.formatCollectionRequestData(data);
          this.setTableInfo(meta);
        } catch (err) {
          this.toggleSnackbar({
            text: err?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs();
          this.crcs = [
            ...data,
            {
              description: "Todos",
              id: ""
            }
          ];
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      goToAddRequest() {
        this.$router.push({
          name: "newRequest"
        });
      },

      updateData() {
        this.getCollectionData();
      },

      formatCollectionRequestData(collection) {
        return collection.map(data => {
          const phone = data.phones.length
            ? this.parsePhone(data.phones[0])
            : "não informado";

          return {
            id: data.id,
            createdAt: this.parseDate(data.createdAt, "dd/MM/yyyy - HH:mm"),
            description: data.type.description,
            sourceName: data.sourceName,
            phone,
            situation: data.situation.description,
            address: data.address,
            situationId: data.situation.id
          };
        });
      },

      debounceInput: debounce(async function(value) {
        this.searchCRC = value;
      }, 500)
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  @include search-bar();
</style>
