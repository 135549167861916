<template>
  <v-dialog v-model="state.dialog" scrollable :persistent="true" width="488">
    <v-card class="modal">
      <v-card-title class="modal-title">{{ situationModalTitle }}</v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="pt-5">
          <p>Tem certeza que deseja cancelar a solicitação?</p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex flex-wrap justify-space-between">
        <v-btn
          class="mt-1 black-3--text cancel-button"
          color="#ffffff"
          :block="$isMobile"
          @click="state.dialog = false"
          >Cancelar</v-btn
        >
        <v-btn
          type="button"
          @click="actionChangeSituation"
          color="error"
          class="ma-0 mt-1 cancel-button"
          :block="$isMobile"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from "vuex";

  export default {
    name: "ChangeScheduleSituationDialog",

    props: {
      user: {
        required: true,
        type: Object
      },
      state: {
        required: true,
        type: Object
      }
    },
    data: () => ({
      dialogChangeRequestSituation: false,
      situationModalTitle: ""
    }),
    mounted() {
      this.initialize();
    },
    watch: {
      "request.situation": function() {
        this.initialize();
      }
    },
    methods: {
      ...mapActions("user", ["changeSituation"]),
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("agenda", ["deleteCollectionSchedule"]),

      initialize() {
        this.state.canChangeSituation = true;
        this.situationModalTitle = `Cancelar solicitação`;
      },

      async actionChangeSituation() {
        try {
          const response = await this.deleteCollectionSchedule({
            scheduleId: this.user.id
          });

          this.$emit("success");
          this.toggleSnackbar({
            text: response?.message ?? "Solicitação cancelada.",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        } finally {
          this.state.dialog = false;
        }
      }
    }
  };
</script>
<style></style>
